<template>
  <v-data-table
    v-if="tableDataConfig"
    hide-default-footer
    :eagle-table="listKey"
    class="elevation-4"
    disable-sort
    calculate-widths
    dense
    fixed-header
    mobile-breakpoint="100"
    :height="height"
    :show-select="hasSelectFeature"
    :headers="headerConfig"
    :items="listData"
    :items-per-page="tablePerPage"
  >
    <!-- 全選checkbox不顯示 -->
    <template v-slot:header.data-table-select></template>

    <!-- 勾選 -->
    <template v-slot:item.data-table-select="{ item, index }">
      <rowSelect
        :list-key="listKey"
        :row="item"
        :index="index"
      ></rowSelect>
    </template>

    <!-- header -->
    <template
      v-for="(dataConfig, dataConfigIndex) in tableDataConfig"
      v-slot:[`header.${dataConfig.key}`]="{ header }"
    >
      <listTableHeaderItem
        :key="`data-table-header-${dataConfigIndex}`"
        :dataConfig="header"
      ></listTableHeaderItem>
    </template>

    <!--data-->
    <template
      v-for="(dataConfig, dataConfigIndex) in tableDataConfig"
      v-slot:[`item.${dataConfig.key}`]="{ item, index }"
    >
      <listTableDataItem
        :key="`data-table-data-${dataConfigIndex}`"
        :index="index"
        :row="item"
        :config="dataConfig"
        :name="dataConfig.key"
        :list-key="listKey"
      ></listTableDataItem>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  methods: {
    hasSetupListAction(tableDataConfig) {
      if(!Array.isArray(tableDataConfig)) return false
      return tableDataConfig.some(item => item.key == 'list-action')
    },
  },
  computed: {
    hasSelectFeature() {
      return this.$store.getters[`list/${this.listKey}/hasSelectFeature`]
    },
    headerConfig() {
      let headerConfig = []
      const computedDataConfig = this.tableDataConfig.map(dataConfig => ({
        ...dataConfig,
        value: dataConfig.key,
      }))

      headerConfig = window.window.eagleLodash.merge(headerConfig, computedDataConfig)
      return headerConfig
    },
    tableDataConfig() {
      let tableDataConfig = this.$store.getters[`list/${this.listKey}/tableDataConfig`]
      if(this.listDataAction && !this.hasSetupListAction(tableDataConfig)) {
        tableDataConfig.push({
          key: 'list-action',
          label: '',
          type: 'data-action'
        })
      }
      return tableDataConfig
    },
    listDataAction() {
      return this.$store.getters[`list/${this.listKey}/dataAction`]
    },
    selectorSingleMode() {
      return this.$store.getters[`list/${this.listKey}/selectorSingleMode`]
    },
    listData() {
      return this.$store.getters[`list/${this.listKey}/data`]
    },
    pager() {
      return this.$store.getters[`list/${this.listKey}/pager`]
    },
    height() {
      if(this.dataQuantity == 0) return '100px'
      if(this.dataQuantity < 2) return `${this.dataQuantity*220}px`
      return '440px'
    },
    dataQuantity() {
      return this.$store.getters[`list/${this.listKey}/dataQuantity`]
    },
    tablePerPage() {
      if(!this.pager) {
        return this.dataQuantity
      }
      return this.$store.getters[`list/${this.listKey}/perPage`]
    },
  },
  components: {
    listTableHeaderItem: () => import('@/components/list/tableMode/headerItem.vue'),
    listTableDataItem: () => import('@/components/list/tableMode/dataItem.vue'),
    rowSelect: () => import('@/components/list/tableMode/rowSelect.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
