var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableDataConfig)?_c('v-data-table',{staticClass:"elevation-4",attrs:{"hide-default-footer":"","eagle-table":_vm.listKey,"disable-sort":"","calculate-widths":"","dense":"","fixed-header":"","mobile-breakpoint":"100","height":_vm.height,"show-select":_vm.hasSelectFeature,"headers":_vm.headerConfig,"items":_vm.listData,"items-per-page":_vm.tablePerPage},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('rowSelect',{attrs:{"list-key":_vm.listKey,"row":item,"index":index}})]}},_vm._l((_vm.tableDataConfig),function(dataConfig,dataConfigIndex){return {key:("header." + (dataConfig.key)),fn:function(ref){
var header = ref.header;
return [_c('listTableHeaderItem',{key:("data-table-header-" + dataConfigIndex),attrs:{"dataConfig":header}})]}}}),_vm._l((_vm.tableDataConfig),function(dataConfig,dataConfigIndex){return {key:("item." + (dataConfig.key)),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('listTableDataItem',{key:("data-table-data-" + dataConfigIndex),attrs:{"index":index,"row":item,"config":dataConfig,"name":dataConfig.key,"list-key":_vm.listKey}})]}}})],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }